<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Companies</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap justify-between ag-grid-table-actions-right"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-0 sm:mr-4 w-full sm:w-auto"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />

              <vx-tooltip text="Export Excel" class="w-full sm:w-auto">
                <a
                  :href="
                    excelurl +
                      `/v2/company/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4 w-full sm:w-auto">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="
                    excelurl +
                      `/v2/company/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=4&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4 w-full sm:w-auto">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="companies"
          rowSelection="multiple"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "@/views/ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import CompanyService from "@/services/companyService.js";
import CompanyStatus from "@/views/apps/masters/company/CompanyStatus.vue";
import SearchService from "@/services/searchService.js";
import EditCompany from "@/views/apps/masters/company/EditCompany.vue";
import CompanyArchives from "@/views/apps/masters/company/CompanyArchives.vue";
import CompanyDetails from "@/views/apps/masters/company/CompanyDetails.vue";
import { BASEURL, ORG_LOGO } from "@/config/index.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>sjd</span>`,
    },
  },
  props: ["listType"],
  data() {
    return {
      ORG_LOGO,
      excelurl: BASEURL,
      archive: this.listType,
      companies: [],
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      totalPages: 0,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      // columnDefs: null,
      contacts: contacts,
      columnDefs: [],
      organization_id: "",
    };
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");

    let loginUserRole = window.localStorage.getItem("UserRole");

    eventBus.$on("refreshTablecomp", (e) => {
      this.getCompanyList(this.currentPage);
    });

    if (loginUserRole === "Admin") {
      this.columnDefs = [
        {
          headerName: "Logo",
          field: "company_logo",
          filter: false,
          width: 90,
          sortable: false,
          suppressSizeToFit: true,
          cellRenderer: function(params) {
            if (
              params.value &&
              params.value.length > 0 &&
              params.value != "-"
            ) {
              return `<img  class="img-rounded" src=${params.value +
                "?token=" +
                window.localStorage.getItem("TempToken")} />`;
            } else {
              return `<img class="img-rounded" src="${ORG_LOGO}" width='40px' height='40px'/>`;
            }
          },
        },
        {
          headerName: "Name",
          field: "company_name",
          filter: false,
          minWidth: 300,
          maxWidth: 475,
          width: 255,
        },
        // {
        //   headerName: "Company Email",
        //   field: "company_email",
        //   filter: false,
        //   width: 315,
        // },
        // {
        //   headerName: "Company Phone",
        //   field: "company_phone",
        //   filter: false,
        //   width: 175,
        // },
        // {
        //   headerName: "Company Address",
        //   field: "company_address",
        //   filter: true,
        //   width: 275,
        // },
        // {
        //   headerName: "Website",
        //   field: "company_url",
        //   filter: false,
        //   width: 275,
        // },
        {
          headerName: "Status",
          field: "department_active",
          filter: false,
          width: 95,
          sortable: false,
          cellStyle: { "text-align": "center" },
          hide: this.archive == "archive" ? true : false,
          cellRendererFramework: CompanyStatus,
        },
        {
          headerName: "Details",
          field: "company_id",
          filter: false,
          width: 100,
          sortable: false,
          cellRendererFramework: CompanyDetails,
        },
        {
          headerName: "Edit",
          field: "",
          filter: false,
          width: 80,
          sortable: false,
          cellStyle: { "text-align": "center" },
          hide: this.archive == "archive" ? true : false,
          cellRendererFramework: EditCompany,
        },
        {
          headerName: this.archive == "archive" ? "Unarchive" : "Archive",
          field: this.archive ? "1" : "4",
          filter: false,
          sortable: false,
          width: this.archive ? 140 : 104,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: CompanyArchives,
        },
      ];
    } else {
      this.columnDefs = [
        {
          headerName: "Logo",
          field: "company_logo",
          filter: false,
          width: 150,
          sortable: false,
          suppressSizeToFit: true,
          cellRenderer: function(params) {
            if (
              params.value &&
              params.value.length > 0 &&
              params.value != "-"
            ) {
              return `<img  class="img-rounded" src=${params.value +
                "?token=" +
                window.localStorage.getItem("TempToken")} />`;
            } else {
              return `<img class="img-rounded" src="${ORG_LOGO}" width='40px' height='40px'/>`;
            }
          },
        },
        {
          headerName: "Name",
          field: "company_name",
          filter: false,
          width: 255,
        },
        {
          headerName: "Details",
          field: "company_id",
          filter: false,
          minWidth: 100,
          maxWidth: 150,
          sortable: false,
          cellRendererFramework: CompanyDetails,
        },
      ];
    }
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        // if (this.gridApi) return this.gridApi.paginationGoToPage(val - 1);
        if (val) {
          this.getCompanyList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    // updateSearchQuery(val) {
    //   this.gridApi.setQuickFilter(val);
    // },
    updateSearchQuery(val) {
      SearchService.getAllSerchResult(val, "company", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.companies = data.data;
          } else {
            this.companies = [];
            // this.$vs.notify({
            //   title: "Search data not found!",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    autoSizeAll() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function(column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    },
    getCompanyList: function(currentPage) {
      let filter = {
        page: currentPage,
      };
      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      // console.log("RolesService :", RolesService);
      CompanyService.getAllCompanies(filter)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
            // if (this.archive == "archive") {
            //   this.companies = data.data.filter(
            //     ({ company_active }) =>
            //       String(company_active) === "4",
            //   );
            // } else {
            //   this.companies = data.data.filter(x => {
            //     console.log("x", x);
            //     return (
            //       String(x.company_active) === "1" ||
            //       String(x.company_active) === "2"
            //     );
            //   });
            // }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
  },
  mounted() {
    if (this.checkPermission(["company", "GET"])) {
      this.getCompanyList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>

<style>
.img-rounded {
  border-radius: 50%;
  max-width: 40px;
  margin-top: 2px;
  height: 40px;
  object-fit: cover;
  object-position: center;
}
</style>
