<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/master">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <!-- COMPANY FORMS     -->
      <div class="vx-col w-full mb-base" v-if="UserRole != 'Admin Manager'">
        <CompanyForm v-if="checkPermission(['master/company', 'POST'])" />
      </div>

      <vs-tabs v-if="checkPermission(['company', 'GET'])">
        <vs-tab label="Company List" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <!-- COMPANY LIST -->
            <div>
              <CompanyList />
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Archive List" icon-pack="feather" icon="icon-box">
          <div>
            <CompanyList listType="archive" />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import CompanyForm from "@/views/apps/masters/company/CompanyForm.vue";
import CompanyList from "@/views/apps/masters/company/CompanyList.vue";
// import CityForm from "@/views/apps/masters/city/CityForm.vue";
// import CityList from "@/views/apps/masters/city/CityList.vue";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
    };
  },
  computed: {
    UserRole() {
      return localStorage.getItem("UserRole") || null;
    },
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    },
  },
  components: {
    CompanyForm,
    CompanyList,
  },
};
</script>

<style lang="scss"></style>
